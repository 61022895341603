import React, { useContext, useEffect, useState } from "react";
import classes from "./Services.module.css";
import AuthContext from "../../store/auth-context";
import Helpers from "../../utils/Helpers";
import { useNavigate } from "react-router-dom";
import { images } from "../../assets/img";
import RemoteConfig from "../../services/RemoteConfig";
import BookNowBtn from "../BookNowBtn";
import "../../index.css";
import MainCarousel from "../Carousel/Carousel";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

const SERVICES = [
  {
    image: images.pedicure,
    title: "Pedicure",
    description: "Indulge in a soothing foot care ritual designed to refresh and revitalize, leaving your feet soft and pampered.",
  },
  {
    image: images.banner02,
    title: "Manicure",
    description: "Experience professional hand and nail care that enhances your natural beauty, leaving your hands impeccably groomed.",
  },
  {
    image: images.nail01,
    title: "Biab/Builder Gel",
    description: "Strengthen and beautify your nails with our long-lasting gel treatment, perfect for adding structure and resilience.",
  },
  {
    image: images.nail04,
    title: "Acrylic",
    description: "Enjoy durable, versatile nail extensions expertly crafted to suit your style, offering elegance and endurance.",
  },
  {
    image: images.nail05,
    title: "SnS",
    description: "Discover the healthier alternative to traditional polish with our powder dipping system, for strong, beautiful nails with a natural finish.",
  },
];


const Services = (props) => {
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();

  const renderServices = () => {
    function chunkArray(arr, chunkSize) {
      const result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize));
      }
      return result;
    }

    const chunkedServiceArray = chunkArray(SERVICES, isMobile ? 1 : 3);

    return chunkedServiceArray.map((serviceGroup, index) => {
      return (
        <div className={classes.servicesRow} key={index}>
          {serviceGroup.map(({ title, description, image }, index) => {
            return (
              <div className={classes.serviceItem}>
                <div
                  style={{
                    borderTopLeftRadius: "100%",
                    borderTopRightRadius: "100%",
                    borderWidth: "1px",
                    borderColor: "red",
                    padding: "1px",
                    backgroundColor: "#000",
                  }}
                >
                  <img className={classes.serviceImg} alt={title} src={image} />
                </div>

                <div className={classes.serviceItemTextContainer}>
                  <h2>{title}</h2>
                  <h4>{description}</h4>
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <div ref={props?.serviceRef} className={classes.wrapper}>
      <h1 className={classes.title}>Our Services</h1>

      <MainCarousel>{renderServices()}</MainCarousel>

      <BookNowBtn
        style={classes.priceListBtn}
        onClick={() => navigate("/services")}
        title={"VIEW PRICING"}
      />
    </div>
  );
};

export default Services;
