import React, { useEffect, useRef, useContext, useState } from "react";
import classes from "./Home.module.css";
import Header from "../../components/Header/Header";
import Services from "../../components/Services/Services";
import Introduction from "../../components/Introduction/Introduction";
import { useLocation, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import PrimaryBanner from "../../components/PrimaryBanner/PrimaryBanner";
import Introduction02 from "../../components/Introduction02/Introduction02";
import InstagramFeed from "../../components/InstagramFeed/InstagramFeed";
import Contacts from "../../components/Contacts/Contacts";
import FooterInfo from "../../components/FooterInfo/FooterInfo";
import Booking from "../../components/Booking/Booking";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";
import CustomerFeedback from "../../components/CustomerFeedback/CustomerFeedback";
import BackToTop from "../../components/BackToTop/BackToTop";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import { images } from "../../assets/img";

const Home = () => {
  const location = useLocation();
  const { state } = location || {};
  const { isFocusServices, focusServices } = state || {};
  const serviceRef = useRef(null);
  const introductionRef = useRef(null);
  const contactsRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useCheckIsMobile();
  const isPopupShown = useRef(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(
          `https://api.imgur.com/3/album/tk-nails-EEcKRZN`,
          {
            method: "GET",
            // headers: {
            //   Authorization: `Client-ID ecc939fbe1a915e`,
            // },
          }
        );

        // console.log("afdiyugadsf===ERROR", response);

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error("Error fetching Imgur album images:", errorDetails);
          throw new Error(`Request failed with status ${response.status}`);
        }

        const data = await response.json();
        console.log("afdiyugadsf", JSON.stringify(data));

        // setImages(data.data); // `data` contains the array of image objects
      } catch (error) {
        console.error("Error fetching Imgur album images:", error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (focusServices) {
      setTimeout(() => {
        let sectionRef = null;
        switch (focusServices) {
          case "contacts":
            sectionRef = contactsRef;
            break;

          default:
            sectionRef = contactsRef;
            break;
        }
        sectionRef?.current?.scrollIntoView({ behavior: "smooth" });
        window.history.replaceState({}, document.title); // Clear the  state
      }, 500);
    }
    if (searchParams.get("booking")) {
      // Ex: http://localhost:3000/?booking=true
      //TODO: Replace serviceRef with bookingRef
      serviceRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [focusServices]);

  const renderTopSeparatorMobile = () => {
    if (!isMobile) return null;

    return (
      <div className={classes.topSeparator}>
        <a className={classes.bookNowA} href={`tel:${NAIL_SHOP_INFO.tel}`}>
          {`PHONE: ${NAIL_SHOP_INFO.phone}`}
        </a>
      </div>
    );
  };

  function AutoClosePopup() {
    const [showPopup, setShowPopup] = useState(false);
  
    useEffect(() => {
      // Check session storage to see if the popup has already been shown in this tab
      const hasShownPopup = sessionStorage.getItem('hasShownPopup');
      let timer, autoCloseTimer;
  
      if (!hasShownPopup) {
        // Delay showing the popup for 1 second
        timer = setTimeout(() => {
          setShowPopup(true);
          sessionStorage.setItem('hasShownPopup', 'true'); // Mark as shown in this tab
        }, 500);
  
        // Auto-close the popup after 60 seconds
        // autoCloseTimer = setTimeout(() => setShowPopup(false), 60000);
      }
  
      return () => {
        // Cleanup timers when component unmounts
        clearTimeout(timer);
        // clearTimeout(autoCloseTimer);
      };
    }, []);
  
    const closePopup = () => {
      setShowPopup(false);
      sessionStorage.setItem('hasShownPopup', 'true'); // Ensure it's marked as shown
    };
  
    return (
      <>
        {showPopup && (
          <div className={classes.overlay} onClick={closePopup}>
            <div className={classes.popup} onClick={(e) => e.stopPropagation()}>
              <img
                onClick={closePopup}
                src={images.popup}
                alt="promotion-popup"
              />
            </div>
          </div>
        )}
      </>
    );
  }
  


  return (
    <div className={classes.helloworld}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{NAIL_SHOP_INFO.name}</title>
        <meta name="service-details" content="Service description" />
        <meta name="description" content="Best Nail Bar" />
      </Helmet>
      {renderTopSeparatorMobile()}
      <Header selected={"home"} />
      <PrimaryBanner />
      <Services serviceRef={serviceRef} />
      <Introduction introductionRef={introductionRef} />
      <Contacts contactsRef={contactsRef} />
      <FooterInfo />
      <FloatingButton />
      <AutoClosePopup />
    </div>
  );
};
export default Home;
